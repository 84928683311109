// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login/route.tsx");
}
// REMIX HMR END

import { Form, json, redirect, useActionData } from "@remix-run/react";
import { prisma } from "~/db.server";
import { createUserSession, getUser, login } from "~/services/session.server";
import { cssBundleHref } from "@remix-run/css-bundle";
import css from "public/assets/css/dashboards.css";
import { Button, Card, CardBody, Input } from "@nextui-org/react";
import { Col, Row } from "react-bootstrap";
export async function loader({
  request
}) {
  const users = await prisma.user.findMany();
  const user = await getUser(request);
  if (user) {
    return redirect("/");
  }
  return json({
    users
  });
}
export const links = () => [...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : []), {
  rel: "stylesheet",
  href: css
}];
export async function action({
  request
}) {
  let formData = await request.formData();
  let username = formData.get("username");
  let password = formData.get("password");
  let password_confirm = formData.get("password_confirm");
  username = username ? username.toString() : "";
  password = password ? password.toString() : "";
  if (username && password) {
    const user = await login({
      username,
      password
    });
    const fields = {
      username,
      password
    };
    if (!user) {
      return json({
        error: "Invalid username or password",
        fields
      }, {
        status: 401
      });
    }
    return createUserSession(user.id, "/");
  }
  return json({
    error: "A username and password are required"
  }, {
    status: 400
  });
}
export const meta = () => {
  return [{
    title: "The Plants Project"
  }, {
    name: "description",
    content: "A CMSC 435 Project"
  }];
};
export default function Login() {
  _s();
  const actionData = useActionData();
  return <div className="main flex h-screen justify-center items-center">
      <Card className="dark p-8 rounded-md m-2 md:w-1/2">
        <CardBody>
          <Row>
            <Col>
              <h1 className="text-3xl font-bold text-center mb-4">Login</h1>
              <Form method="post">
                <Row>
                  <Col>
                    <Input type="text" label={"Username"} name="username" />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Input type="password" label="Password" name="password" />
                  </Col>
                </Row>
                {actionData?.error && <Row className="mt-2">
                    <div className="p-2 bg-red-100 text-red-800 text-sm rounded">
                      {actionData.error}
                    </div>
                  </Row>}

                <Row className="mt-3">
                  <Col xs={12} md={12}>
                    <Button type="submit" color="primary" className="xs:w-full md:w-full">

                      Sign In
                    </Button>
                  </Col>
                  <Col xs={12} md={12} className="text-end">
                    <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 mt-2" href="register">

                      Not registered? Sign up
                    </a>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>;
}
_s(Login, "fHVw5pq0Zwd2gXh2gyrnVdHnLCc=", false, function () {
  return [useActionData];
});
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;